import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { LoginForm } from './';
import { Config, Constants, Auth, QbApi } from '../modules';
import { withTranslation } from 'react-i18next';
import { AppStateContext } from '../modules';

function LoginError(errorString, ...args) {
    const instance = Reflect.construct(Error, args);
    Reflect.setPrototypeOf(instance, Reflect.getPrototypeOf(this));
    instance.errorString = errorString;
    instance.name = 'LoginError';
    return instance;
}

LoginError.prototype = Object.create(Error.prototype, {
    constructor: {
        value: Error,
        enumerable: false,
        writable: true,
        configurable: true
    }
} );

Reflect.setPrototypeOf(LoginError, Error);

class LoginPage extends React.Component {
    /**
     * Class constructor.
     */
    constructor(props) {
        super(props);

        let redirectTo = '/dashboard';
        try {
            redirectTo = props.location.state.from.pathname;
        } catch (e) {
        }

        // set the initial component state
        this.state = {
            errors: { },
            user: {
                username: '',
                password: '',
            },
            redirect: false,
            redirectTo: redirectTo,
            ajaxInProgress: false,
        };

        this.processForm = this.processForm.bind(this);
        this.changeUser = this.changeUser.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    /**
     * Process the form.
     *
     * @param {object} event - the JavaScript event object
     */
    async processForm(event) {
        // prevent default action. in this case, action is the form submission event
        event.preventDefault();
        this.setState( { ajaxInProgress: true } );

        var sprintf = require('sprintf-js').sprintf;
        var crypto = require('crypto');
        // TODO: https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/btoa#Unicode_Strings
        const buf = Buffer.from(this.state.user.password);
        //console.log(buf.toString());
        const encPassword = crypto.publicEncrypt(Config.authKey, buf).toString('base64');
        //console.log(encPassword);

        const apiData = {
            username: this.state.user.username,
            password: encPassword
//password: this.state.user.password // XXX: debug
        };
        try {
            let callLogin = fetch(Config.apiUrl + 'auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(apiData),
            } );
            let responseLogin = await callLogin;
            let dataLogin = await responseLogin.json();
            if ('errorCode' in dataLogin) {
                let errorMsgString = 'kBackendErrorMessageAuthenticationFailedInvalidUsernamePassword';
                if (Number(dataLogin.errorCode) === 14) {
                    errorMsgString = 'localizeBackendErrorLicenseError';
                }
                throw new LoginError(errorMsgString, 'invalid username or password: ' + dataLogin.message + ' (errorCode: ' + dataLogin.errorCode + ')');
            }

            Auth.authenticateUser(dataLogin);
            //this.context.setAuthPhase('hasData'); // XXX: nem állíthatjuk be, mert újrarendereli a LoginForm-ot, és eltünteti a hiba megjelenítését
            this.updateLanguage(dataLogin);

            const apiCallDescriptors = [
                //{ url: Config.apiUrl + 'config',            authItem: 'clientConfig',       errorMessage: 'Error getting config: %s (errorCode: %s)',                   fatal: true,                  },
                { url: Config.apiUrl + 'legal/documents',   authItem: 'legalDocuments',     errorMessage: 'Error getting legal documents: %s (errorCode: %s)',          fatal: true,                  },
                { url: Config.apiUrl + 'legal/missing',     authItem: 'legalMissing',       errorMessage: 'Error getting missing legal documents: %s (errorCode: %s)',  fatal: true,                  },
                { url: Config.apiUrl + 'userprofile/image', authItem: 'userprofileImage',   errorMessage: 'Error getting useprofile/image: %s (errorCode: %s)',         fatal: false, _default: null,
                    assign: (value) => ( { data: Auth.decrypt(value.data).replaceAll(/[\r\n]/g, '').trim() } ),
                },
            ];

            for (const apiCallDescriptor of apiCallDescriptors) {
                let response = await QbApi.instance.fetch(this.context, apiCallDescriptor.url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Authorization': 'Bearer ' + Auth.getToken()
                    },
                } );
                let data = null;
                try {
                    data = await response.json();
                    if (apiCallDescriptor.hasOwnProperty('assign')) {
                        data = apiCallDescriptor.assign(data);
                    }
                    if ('errorCode' in data) {
                        throw new LoginError('localizeAuthLoginErrorUnknown', sprintf(apiCallDescriptor.errorMessage, data.message, data.errorCode));
                    }
                } catch (error) {
                    if (apiCallDescriptor.fatal) {
                        throw error;
                    } else {
                        data = apiCallDescriptor._default;
                    }
                }
                Auth.setItem(apiCallDescriptor.authItem, data);
            }

            let apiCallDescriptor = { url: Config.apiUrl + 'info/client/' + Constants.appInfo.platform, authItem: 'clientInfo',     errorMessage: 'Error getting client info: %s (errorCode: %s)' };
            let response = await QbApi.instance.fetch(this.context, apiCallDescriptor.url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': 'Bearer ' + Auth.getToken()
                },
            } );
            let data = { };
            try {
                data = await response.json();
            } catch (error) {
                data = { info: { platform: 'web', version: '0.0.0', } };
            }
            if (Number(response.status) === 404) {
                data = { info: { platform: 'web', version: '0.0.0', } };
            }
            if ('errorCode' in data) {
                throw new LoginError('localizeAuthLoginErrorUnknown', sprintf(apiCallDescriptor.errorMessage, data.message, data.errorCode));
            }
            Auth.setItem(apiCallDescriptor.authItem, data);

            this.context.setAuthPhase('legal');
            Auth.setAuthPhase('legal');
        } catch (error) {
            this.setState( { ajaxInProgress: false } );
            let errorString = 'kBackendErrorMessageAuthenticationFailedDefaultError';
            if (error instanceof LoginError) {
                errorString = error.errorString;
            }
            this.setState( {
                errors: {
                    summary: this.props.t(errorString),
                    username: this.props.t(errorString),
                    debug: (Constants.debug.loginPage ? sprintf('%s\n%s', error.toString(), error.stack) : null),
                }
            } );
            Auth.deauthenticateUser();
            this.context.setAuthPhase('unauth');
        }
        this.setState( { ajaxInProgress: false } );
    }

    /**
     * Change the user object.
     *
     * @param {object} event - the JavaScript event object
     */
    changeUser(event) {
        const field = event.target.name;
        const user = this.state.user;
        user[field] = event.target.value;

        this.setState( {
            user,
        } );
    }

    onBlur(event) {
        this.setState( {
            errors: { }
        } );
    }

    updateLanguage(authData) {
        var languageCode = '';
        try {
            languageCode = authData.userProfile.settings.language.code;
        } catch (e) {
        }
        // TODO: ellenőrizni, hogy a language code létezik-e, mint i18n.js -ben; ha nem, akkor languageCode = '', hogy a detector plugin vegye át a szerepet
        // TODO: a language listát i18n.js-ből és SettingsLanguage.js-ből át kell tenni valami külsö(bb) config file-ba
        localStorage.setItem('qbLanguage', languageCode);
        this.props.i18n.changeLanguage(languageCode);
    }

    /**
     * Render the component.
     */
    render() {
        if (this.state.redirect === true) {
            return <Redirect to={ this.state.redirectTo } />;
        }
        if (this.context.authPhase === 'legal') {
            return <Redirect to={ this.state.redirectTo } />;
        }
        if (this.context.authPhase === 'auth') {
            return <Redirect to={ this.state.redirectTo } />;
        }
        return (
            <LoginForm
                onSubmit={ this.processForm }
                onChange={ this.changeUser }
                onBlur={ this.onBlur }
                errors={ this.state.errors }
                user={ this.state.user }
                ajaxInProgress={ this.state.ajaxInProgress }
                authPhase={ this.context.authPhase }
            />
        );
    }

}

LoginPage.contextType = AppStateContext;

export default withRouter(withTranslation()(LoginPage));
